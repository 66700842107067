import { SelectContent, SelectItem } from '@components/ui/select'

import { z } from 'zod'

const countryOptions: readonly [string, ...string[]] = [
  'Australia',
  'Malaysia',
  'New Zealand',
  'Philippines',
  'Singapore',
  'Other',
]

export const SelectCountryContent = () => (
  <SelectContent>
    {countryOptions.map((industry, index) => (
      <SelectItem key={index} value={industry}>
        {industry}
      </SelectItem>
    ))}
  </SelectContent>
)

export const zodCountryOptions = z.enum(countryOptions, {
  message: 'Please select a country',
})
