import { useEffect, useMemo, useState } from 'react'

const snakeToCamel = (str: string) =>
  str.replaceAll(/_./g, (x) => x.toUpperCase()[1])

type UtmFieldMap = Record<string, string | undefined>
type UtmFieldMapList = UtmFieldMap[]

const useUtmFields = () => {
  const [utmFields, setUtmFields] = useState([] as UtmFieldMapList)
  useEffect(() => {
    // Get UTM parameters
    const dhmUrlParams836 = new URLSearchParams(window.location.search)
    const dhmUtms836 = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term']
    const fields = []

    for (const element of dhmUtms836) {
      const utmElFromUrl = dhmUrlParams836.get(element)
      // utm fields get lost in URL because visitor jumped a few pages within our site
      // when it's null, try to retrieve it from local storage
      if (utmElFromUrl == null) {
        const utmElFromLocal = localStorage.getItem('dhm836_' + element)
        utmElFromLocal != null && fields.push({ [element]: utmElFromLocal })
      }
      // direct visit with utm in the URL
      else {
        localStorage.setItem('dhm836_' + element, utmElFromUrl)
        fields.push({ [element]: utmElFromUrl })
      }
    }

    // save the UTMs
    setUtmFields(fields)
  }, [])
  return utmFields
}

export const useUtmInitialValues = () => {
  const utmFields = useUtmFields()
  return useMemo(() => {
    const initialValues: UtmFieldMap = {}

    for (const field of utmFields) {
      const key = Object.keys(field)[0]
      const camelKey = snakeToCamel(key)
      initialValues[camelKey] = field[key]
    }

    return initialValues
  }, [utmFields])
}
