if (!process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY) {
  throw new Error(
    "Environment variable 'NEXT_PUBLIC_RECAPTCHA_SITE_KEY' has not been set",
  )
}

if (
  !process.env.RECAPTCHA_THRESHOLD &&
  Number(process.env.RECAPTCHA_THRESHOLD) > 0
) {
  throw new Error("Environment variable 'RECAPTCHA_THRESHOLD' has not been set")
}

interface reCAPTCHA {
  siteKey: string
  threshold: number
}

const recaptcha: reCAPTCHA = {
  siteKey: process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY.trim(),
  threshold: Number(process.env.RECAPTCHA_THRESHOLD),
}

export default recaptcha
