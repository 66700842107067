import Script from 'next/script'
import config from '@config/recaptcha'

export default function Recaptcha() {
  return (
    <Script
      id='recaptcha'
      async
      src={`https://www.google.com/recaptcha/enterprise.js?render=${config.siteKey}`}
    />
  )
}
